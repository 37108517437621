<template>
  <div class="about">
    <div class="site-section bg-light">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" style="margin-top: 60px;">
            <h2 class="section-title mb-3 text-normal-blue">Contact Us</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-6" data-aos="fade-right">
            <img src="images/undraw_contact_us_15o2.svg" alt="Image" class="img-fluid">
          </div>
          <div class="col-lg-5 ml-auto pl-lg-5">
            <h4 class="text-black mb-4 h4 font-weight-bold text-dark-blue">Addresses</h4>
            <p class="mb-4"></p>
            <ul class="ul-check mb-5 list-unstyled success text-black">
              <li>625 Gene Autry Ln, Suite 100, Murphy, TX 75094</li>
              <li>contact@clikry.com</li>
              <li>www.clikry.com</li>
              <li>+1 972 836 9241</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <hr>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7 mb-5">
            <form action="#" method="post">       
              <h2 class="h4 text-black mb-5">Contact Form</h2> 
              <div class="row form-group">
                <div class="col-md-6 mb-3 mb-md-0">
                  <label class="text-black" for="fname">First Name</label>
                  <input type="text" id="fname" class="form-control rounded-0">
                </div>
                <div class="col-md-6">
                  <label class="text-black" for="lname">Last Name</label>
                  <input type="text" id="lname" class="form-control rounded-0">
                </div>
              </div>
              <div class="row form-group">                
                <div class="col-md-12">
                  <label class="text-black" for="email">Email</label> 
                  <input type="email" id="email" class="form-control rounded-0">
                </div>
              </div>
              <div class="row form-group">             
                <div class="col-md-12">
                  <label class="text-black" for="subject">Subject</label> 
                  <input type="subject" id="subject" class="form-control rounded-0">
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="message">Message</label> 
                  <textarea name="message" id="message" cols="30" rows="7" class="form-control rounded-0" placeholder="Leave your message here..."></textarea>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <input type="submit" value="Send Message" class="btn btn-primary mr-2 mb-2">
                </div>
              </div>  
            </form>
          </div>        
        </div>        
      </div> -->
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer
  }
};
</script>